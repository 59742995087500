



































import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";
import Spinners from "@/components/utilities/Spinners.vue";
import ExhibitorLogoCard from "@/components/shared/ExhibitorLogoCard.vue";
import ExhibitorResultCard from "@/components/shared/ExhibitorResultCard.vue";

const tradeshowStore = getModule(tradeshowVuexModule);

@Component({
    components: {
        Spinners,
        ExhibitorLogoCard,
        ExhibitorResultCard
    }
})
export default class GridDisplayByTierSponsors extends Vue {
    isLoading = false;
    created() {
        tradeshowStore.getTradeshowCompanies().finally(() => {
            this.isLoading = false;
        });
    }

    get tradeshowCompanies() {
        const companies = tradeshowStore.allTradeshowCompanies;

        return companies.sort((cur: any, nxt: any) => {
            if (cur.companyName) {
                return cur.companyName.localeCompare(nxt.companyName);
            } else return cur.name.localeCompare(nxt.name);
        });
    }

    get fallbackImage() {
        const logo = this.$store.getters.getPageOptions("tradeshow")
            .defaultLogo;

        return logo ? `${process.env.BASE_URL}bg/${logo}` : "";
    }

    get pageConfig() {
        return this.$store.getters.getPageOptions("tradeshow");
    }

    // methods
    getLogoImage(exhibitor: any) {
        const returnPath = exhibitor.imageLogo || exhibitor.images?.logo;
        if (!returnPath) {
            return this.fallbackImage;
        } else {
            return returnPath;
        }
    }
}
